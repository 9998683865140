import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {
  useParams,
  useSearchParams,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import moment from 'moment';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useAuth } from 'context/AuthContext';
import { useToast } from 'context/ToastContext';

import Section from 'components/Section';
import routes from 'utils/routes';
import { API, APIRoutes } from 'utils/api';
import Loader from 'components/Loader';
import InfoIcon from 'assets/icons/InfoIcon';
import PinIcon from 'assets/icons/PinIcon';
import CalendarIcon from 'assets/icons/CalendarIcon';
import ClockIcon from 'assets/icons/ClockIcon';
import ToastSuccessIcon from 'assets/icons/ToastSuccessIcon';
import DetailsSection from 'components/Common/DetailsSection';
import LabelWithIcon from 'components/Common/LabelWithIcon';
import SharpenedCard from 'components/Common/SharpenedCard';
import ShowEditOrConfirmButton from 'components/Bookings/ShowEditOrConfirmButton';

import CapacityIcon from 'assets/icons/CapacityIcon';
import SectionHeader from 'components/SectionHeader';
import CustomChip from 'components/Common/CustomChip';
import ScrollTopButton from 'components/Common/ScrollTopButton';
import BookingPricing from 'components/Bookings/BookingPricing';

const confirmBooking = async (data: NewBookingProps | undefined) => {
  await API.post(APIRoutes.bookings.postBooking, data);
};

const fetchBooking = async (endpoint: string) => {
  const {
    data: { data },
  } = await API.get(endpoint);
  return data;
};

const getUpcomingBookingDetails = async (id: string) => {
  const {
    data: { data },
  } = await API.get(APIRoutes.bookings.indexById(id));
  return data;
};

const validateBooking = async (body: any) => {
  const {
    data: { data },
  } = await API.post(APIRoutes.bookings.validateBooking, body);
  return data;
};

const BookingDetail = ({
  isUpcoming,
  isExternal,
  isPartTimeBooking = false,
}: {
  isUpcoming?: boolean;
  isExternal?: boolean;
  isPartTimeBooking?: boolean;
}) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { id = '' } = useParams();
  const { spaces, currentSpaceId, user, isAuthenticated } = useAuth();
  const [discount, setDiscount] = useState<string | null | undefined>(null);

  const getBookingDetails = useCallback(
    (
      resourceId: string,
      from?: string | null | undefined,
      to?: string | null | undefined,
      discountCode?: string | null | undefined,
    ) => {
      const endpoint = isAuthenticated
        ? APIRoutes.bookings.resourceById(resourceId, from, to)
        : APIRoutes.bookings.externalById(resourceId, from, to, discountCode);
      return fetchBooking(endpoint);
    },
    [isAuthenticated],
  );

  const handleFetch = useCallback(
    () =>
      isUpcoming
        ? getUpcomingBookingDetails(id)
        : getBookingDetails(
            id,
            searchParams.get('from'),
            searchParams.get('to'),
            discount,
          ),
    [discount],
  );

  const { isLoading, isRefetching, isSuccess, refetch, data } =
    useQuery<BookingProps>(['booking', id], () => handleFetch());

  const breadcrumbsConfig = useCallback(
    ({
      description,
      space,
    }: {
      description: string;
      space: string | number | undefined;
    }) => [
      {
        label: isPartTimeBooking ? 'Office Day Bookings' : 'Meeting rooms',
        href: isAuthenticated
          ? (isPartTimeBooking
              ? routes.main.officeDayBookingsGallery
              : routes.main.meetingsRoomsGallery)({ space })
          : -1,
      },
      { label: description },
    ],
    [isAuthenticated, data],
  );

  const queryClient = useQueryClient();
  const { snack, setSnack } = useToast();

  const [buttonOff, setButtonOff] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (discount) {
      refetch();
    }
  }, [discount]);

  const handleAdditionalInfo = useMemo(() => {
    if (!isUpcoming)
      return {
        returnToDefaultView: !!location.state,
      };

    return null;
  }, []);
  const bookingData = useMemo(() => {
    if (!data || !spaces || !data.isYoursBooking) return {};

    const start = moment(data?.checkIn);
    const end = moment(data?.checkOut);
    const timeDifference = end.diff(start);
    const duration = moment.utc(timeDifference).format('HH:mm');

    return {
      location: data?.space?.address?.replace('Work.Life ', ''),
      month: moment(data.checkIn).startOf('month').toString(),
      day: moment(data.checkIn).startOf('day').toString(),
      time: start.toString(),
      duration,
      bookingId: id,
      isPartTimeBooking,
    };
  }, [data, spaces, isPartTimeBooking]);

  const addNewBookingData: NewBookingProps = {
    from: moment(searchParams.get('from') as string).toISOString(),
    to: moment(searchParams.get('to') as string).toISOString(),
    resourceId: data?.resource._id,
    title: data?.resource.name,
    isPartTimeBooking,
  };

  const handleSuccess = () => {
    queryClient.invalidateQueries(['bookings', { isPartTimeBooking }]);
    setSnack({
      ...snack,
      message: 'Booking confirmed',
      color: 'blue',
      open: true,
      type: 'success',
      icon: (
        <ToastSuccessIcon
          sx={{
            color: 'transparent',
            width: '20px',
            height: '20px',
          }}
        />
      ),
    });

    setButtonOff(true);

    setTimeout(
      () =>
        navigate(
          (isPartTimeBooking
            ? routes.main.officeDayBookingsGallery
            : routes.main.meetingsRoomsGallery)({
            space:
              currentSpaceId === 0 ? user?.spaceId.toString() : currentSpaceId,
          }),
        ),
      1000,
    );
  };

  const { isLoading: isLoadingBookingRoom, mutate: bookRoom } = useMutation(
    'confirm-booking',
    () => confirmBooking(addNewBookingData),
    {
      onSuccess: () => handleSuccess(),
      retry: 0,
      onError: (error: any) =>
        setSnack({
          ...snack,
          message: error.message,
          color: 'white',
          open: true,
          type: 'error',
          icon: (
            <ErrorOutlineOutlinedIcon
              sx={{
                color: 'white',
                width: '20px',
                height: '20px',
              }}
            />
          ),
        }),
    },
  );

  const bookRoomExternally = useCallback(async () => {
    try {
      await validateBooking(addNewBookingData);
    } catch (e: any) {
      setSnack({
        ...snack,
        message: e.message,
        color: 'white',
        open: true,
        type: 'error',
        icon: (
          <ErrorOutlineOutlinedIcon
            sx={{
              color: 'white',
              width: '20px',
              height: '20px',
            }}
          />
        ),
      });
      return;
    }

    navigate(
      (isPartTimeBooking
        ? routes.main.externalOfficeDayBookingsSignup
        : routes.main.externalBookingsSignup)({
        id,
        resourceName: data?.resource.name,
        spaceId: searchParams.get('spaceId'),
        from: searchParams.get('from'),
        to: searchParams.get('to'),
      }),
      {
        state: { discountCode: data?.discountCode?.code },
      },
    );
  }, [id, searchParams, data?.resource.name, data?.discountCode?.code]);

  if (isLoading || isRefetching) {
    return <Loader />;
  }

  if (isSuccess) {
    const start = moment(
      isUpcoming ? data.checkIn : (searchParams.get('from') as string),
    );
    const end = moment(
      isUpcoming ? data.checkOut : (searchParams.get('to') as string),
    );

    const isBooked = data.isYoursBooking;
    const disabled = moment().isAfter(isPartTimeBooking ? end : start);

    return (
      <>
        <SectionHeader
          title={data.resource.name}
          path={breadcrumbsConfig({
            description: data.resource.name,
            space:
              currentSpaceId === 0 ? user?.spaceId.toString() : currentSpaceId,
          })}
          isBooked={isBooked}
          elements={
            <>
              {start.isSame(moment(), 'day') && (
                <CustomChip label="Today!" variant="filled" color="secondary" />
              )}
              <ShowEditOrConfirmButton
                bookingData={bookingData}
                isBooked={isBooked}
                disabled={disabled}
                bookRoom={isAuthenticated ? bookRoom : bookRoomExternally}
                isHeader
                isLoading={isLoadingBookingRoom}
                buttonOff={buttonOff}
              />
            </>
          }
          backLink={
            handleAdditionalInfo?.returnToDefaultView || isExternal
              ? -1
              : (isPartTimeBooking
                  ? routes.main.officeDayBookingsGallery
                  : routes.main.meetingsRoomsGallery)({
                  space:
                    currentSpaceId === 0
                      ? user?.spaceId.toString()
                      : currentSpaceId,
                })
          }
        />
        <Section>
          <DetailsSection
            leftColumn={
              <SharpenedCard>
                <img
                  src={data.resource.photo.default.url}
                  alt="img"
                  width="100%"
                />
                {isUpcoming && (
                  <BookingPricing
                    isBooked={isBooked}
                    fullPrice={data.fullPrice}
                    disabled={disabled}
                    bookingData={bookingData && bookingData}
                  />
                )}

                {!isUpcoming && (
                  <BookingPricing
                    isBooked={isBooked}
                    fullPrice={data.fullPrice}
                    disabled={disabled}
                    bookingData={bookingData && bookingData}
                    isLoading={isLoadingBookingRoom}
                    buttonOff={buttonOff}
                    bookRoom={isAuthenticated ? bookRoom : bookRoomExternally}
                    setDiscount={setDiscount}
                    discountCode={data.discountCode}
                  />
                )}
              </SharpenedCard>
            }
            rightColumn={
              <>
                <Typography
                  marginBottom="16px"
                  display="flex"
                  variant="h4"
                  fontWeight="fontWeightBold"
                >
                  Location
                </Typography>
                <LabelWithIcon
                  label={data?.space?.address?.replace('Work.Life ', '')}
                  icon={PinIcon}
                />
                <LabelWithIcon
                  label={
                    start.isSame(end, 'day')
                      ? start.format('dddd Do MMMM YYYY')
                      : `${start.format('dddd Do MMMM YYYY')} - ${end.format(
                          'dddd Do MMMM YYYY',
                        )}`
                  }
                  icon={CalendarIcon}
                />
                <LabelWithIcon
                  label={`${start.format('HH:mm')} -  ${end.format('HH:mm')}`}
                  icon={ClockIcon}
                />
                <Divider
                  sx={{
                    marginTop: '16px',
                    marginBottom: '18px',
                  }}
                />
                {data?.resource?.capacity && (
                  <>
                    <LabelWithIcon
                      label="Capacity"
                      icon={CapacityIcon}
                      isHeader
                      capacity
                    />
                    <Typography
                      marginTop="-12px"
                      fontSize={16}
                      fontWeight="fontWeightBold"
                      sx={{
                        width: '18px',
                        textAlign: 'center',
                        paddingBottom: '5px',
                      }}
                    >
                      {data.resource.capacity.toString()}
                    </Typography>
                    <Divider
                      sx={{
                        marginTop: '16px',
                        marginBottom: '18px',
                      }}
                    />
                  </>
                )}
                <LabelWithIcon label="Description" icon={InfoIcon} isHeader />
                <Box
                  sx={{
                    lineHeight: '18px',
                    marginBottom: '12px',
                    overflowWrap: 'break-word',
                    a: {
                      color: 'inherit',
                    },
                  }}
                  dangerouslySetInnerHTML={{
                    __html: data.resource.description.replace('Work.Life ', ''),
                  }}
                />
              </>
            }
          />
        </Section>

        <Box textAlign="center" marginBottom={5}>
          <ScrollTopButton />
        </Box>
      </>
    );
  }
  return null;
};

BookingDetail.defaultProps = {
  isUpcoming: false,
  isExternal: false,
  isPartTimeBooking: false,
};

export default BookingDetail;
