import axios from 'axios';
import { endsWith, isNil, omitBy } from 'lodash-es';
import { stringify } from 'query-string';
import { FILTERS } from 'constants/desks';
import routes from 'utils/routes';
import { setItem, getItem, KEYS, removeItem } from './cache';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

API.defaults.headers.common['x-worklife-version'] = 'work.life';
API.defaults.headers.common['x-worklife-origin'] = 'portal';

const AUTH_TOKEN = getItem(KEYS.AUTH_TOKEN);
const IMPERSONATE_TOKEN = getItem(KEYS.IMPERSONATE_TOKEN);

if (IMPERSONATE_TOKEN) {
  API.defaults.headers.common.authorization = IMPERSONATE_TOKEN;
} else if (AUTH_TOKEN) {
  API.defaults.headers.common.authorization = AUTH_TOKEN;
}

const setAuthenticationToken = (token: string, impersonateToken?: string) => {
  setItem(KEYS.AUTH_TOKEN, token);
  if (impersonateToken) {
    API.defaults.headers.common.authorization = impersonateToken;
  } else {
    API.defaults.headers.common.authorization = token;
  }
};

const destroySession: () => void = () => {
  delete API.defaults.headers.common.authorization;
};

const APIRoutes = {
  auth: {
    signIn: '/sign_in',
    signInRefresh: '/sign_in/token/refresh',
    resetPassword: '/sign_up/resend_invite',
    doResetPassword: '/sign_in/password/reset',
    activation: '/sign_in/activate',
    signUp: '/sign_up',
    signUpPayment: '/sign_up/contract',
    plan: '/sign_up/tariff',
    goCardless: 'goCardless',
    goCardlessFinalize: '/goCardless/mandate',
  },
  platform: {
    parserFeed: (page: number, query?: string | undefined) =>
      `platform/parser-feed?${stringify(omitBy({ page, q: query }, isNil))}`,
  },
  user: {
    profile: '/profile',
    account: '/profile/coworker',
    cardDetails: '/profile/coworker/card-details',
    slack: '/profile/slack_invite',
    metrics: '/profile/metrics',
    bookingsMetrics: (filter: string, month: string) =>
      `/teams/dashboard/bookings${filter || month ? '?' : ''}${stringify(
        omitBy({ filter, month }, isNil),
      )}`,
    plans: '/profile/planAndBenefits',
    cancelContract: (notes: string) =>
      `/profile/coworker/plan?id=99&notes=${notes}`,
    canCancelContract: '/profile/balance',
    usersToImpersonate: (phrase: string) =>
      `/profile/coworker/search?q=${phrase}`,
    impersonateSelectedUser: '/profile/coworker/impersonate',
  },
  perks: {
    index: (limit = 4, page = 1, spaceId = 0) =>
      `/perks?page=${page}&limit=${limit}${
        spaceId ? `&spaceId=${spaceId}` : ''
      }`,
    indexById: (perkID: string) => `/perks/${perkID}`,
  },
  events: {
    index: (limit = 4, page = 1, spaceId = 0, categoryId = 0) =>
      `/events${spaceId ? '' : '/all'}?page=${page}&limit=${limit}${
        spaceId ? `&spaceId=${spaceId}` : ''
      }${categoryId ? `&categoryId=${categoryId}` : ''}`,
    indexById: (eventID: string) => `/events/${eventID}`,
    categories: '/events/categories',
    booked: '/events/booked',
    delete: (eventId: number) => `/events/${eventId}/tickets`,
  },
  announcements: {
    index: (limit = 4, spaceId = 0) =>
      `/alerts?limit=${limit}${spaceId ? `&spaceId=${spaceId}` : ''}`,
  },
  bookings: {
    index: '/bookings',
    my: (params: { isPartTimeBooking?: boolean; showAll?: boolean }) =>
      `/bookings/my?${stringify(params)}`,
    indexById: (bookingID?: string) => `/bookings/${bookingID}`,
    resourceById: (
      resourceId: string,
      from?: string | null | undefined,
      to?: string | null | undefined,
    ) => `/resources/work_life/${resourceId}?from=${from}&to=${to}`,
    externalById: (
      resourceId: string,
      from?: string | null | undefined,
      to?: string | null | undefined,
      discountCode?: string | null | undefined,
    ) =>
      `/resources/external/${resourceId}?from=${from}&to=${to}${
        discountCode ? `&discountCode=${discountCode}` : ''
      }`,
    postBooking: 'bookings/work_life',
    validateBooking: '/bookings/work_life/validate',
    filtered: (params: {
      spaceId: number | undefined;
      capacity: number;
      from: string;
      to: string;
      showAll?: boolean;
      isPartTimeBooking?: boolean;
    }) => `/resources/work_life?${stringify(params)}`,
    external: (params: {
      spaceId: number | undefined;
      capacity: number;
      from: string;
      to: string;
      showAll?: boolean;
      roomId?: number | undefined;
    }) => `/resources/external?${stringify(params)}`,
  },
  spaces: '/spaces',
  bankHolidays: '/sign_up/holidays',
  help: {
    faq: '/support',
    index: '/support/chats',
    tickets: (limit = 4, page = 1) =>
      `/support/chats?closed=false&page=${page}&limit=${limit}`,
    closedTickets: (limit = 4, page = 1) =>
      `/support/chats?closed=true&page=${page}&limit=${limit}`,
    chatByLastId: (id: string, lastMessageId = '') =>
      `/support/chats/${id}${lastMessageId ? `?lastId=${lastMessageId}` : ''}`,
    chatByFirstId: (id: string, firstMessageId = '') =>
      `/support/chats/${id}${
        firstMessageId ? `?firstId=${firstMessageId}` : ''
      }`,
    sendMessage: (id: string) => `/support/chats/${id}/message`,
  },
  alerts: {
    index: (limit = 15, page = 1) =>
      `/alerts/notifications?page=${page}&limit=${limit}`,
    markAsRead: (alertId: string) => `/alerts/notifications/${alertId}`,
    perksAndEvents: `/alerts/perksAndEvents`,
    clearRead: '/alerts/notifications/read',
    clearAll: '/alerts/notifications/all',
  },
  visitors: {
    incoming: (limit = 4, page = 1) =>
      `/visitors/upcoming?page=${page}&limit=${limit}`,
    past: (limit = 4, page = 1) => `/visitors/past?page=${page}&limit=${limit}`,
    remove: (visitorId: number) => `/visitors/${visitorId}`,
    update: (visitorId: number) => `/visitors/${visitorId}`,
    addVisitor: '/visitors',
  },
  deliveries: {
    awaiting: (limit = 4, page = 1) =>
      `/deliveries/awaiting?page=${page}&limit=${limit}`,
    collected: (limit = 4, page = 1) =>
      `/deliveries/collected?page=${page}&limit=${limit}`,
  },
  store: {
    products: '/products',
  },
  basket: {
    index: '/basket',
    add: (id: number) => `/basket/add/product/${id}`,
    patch: (id: number) => `/basket/patch/product/${id}`,
    remove: (id: number) => `/basket/remove/product/${id}`,
    previewInvoice: '/basket/previewInvoice',
    createInvoice: '/basket/createInvoice',
  },
  infogrid: {
    capacities: (date: string, filter: string | null) =>
      `/infogrid/capacities?time=${date}${
        filter && filter !== FILTERS.ALL && filter !== FILTERS.FAVOURITES
          ? `&deskAvailabilityFilter=${filter.toLowerCase()}`
          : ''
      }`,
    capacity: (spaceId: string, date: string) =>
      `/infogrid/capacities/${spaceId}?time=${date}`,
  },
  invoices: {
    index: '/invoices',
    byId: (id?: string) => `/invoices/${id}`,
    pay: (id: string) => `/invoices/${id}/try-payment`,
    statement: '/invoices/statement',
  },
  teams: {
    index: '/teams',
    byId: (id: number) => `/teams/${id}`,
    remove: (teamId: number, memberId: number, notes: string) =>
      `/teams/${teamId}/coworkers/${memberId}?notes=${notes}`,
    add: (teamId: number) => `/teams/${teamId}/coworkers`,
  },
  tourBoking: {
    spaces: '/book-a-tour/spaces',
    host: (id: number) => `/book-a-tour/${id}/host`,
    availability: (spaceId: number, date: string | null) =>
      `/book-a-tour/${spaceId}/availability?date=${date}`,
    book: (id: number) => `/book-a-tour/${id}`,
  },
  onboarding: {
    tasks: '/onboarding',
    filesUpload: '/onboarding/upload',
  },
};

API.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      if (endsWith(error.config.url, APIRoutes.auth.signInRefresh)) {
        destroySession();
        removeItem(KEYS.AUTH_TOKEN);
        removeItem(KEYS.KEEP_LOGGED_IN);
        removeItem(KEYS.REFRESH_TOKEN);
        window.location.replace(routes.auth.signIn);
      }

      const keepLoggedIn = getItem(KEYS.KEEP_LOGGED_IN) as string;
      const refreshToken = getItem(KEYS.REFRESH_TOKEN) as string;

      if (keepLoggedIn === 'true') {
        if (refreshToken) {
          const {
            data: { token: newToken, refreshToken: newRefreshToken },
          } = await API.post(APIRoutes.auth.signInRefresh, {
            token: refreshToken,
          });
          if (newToken) {
            setAuthenticationToken(newToken);
            setItem(KEYS.REFRESH_TOKEN, newRefreshToken);
            window.location.reload();
          }
        }
      } else {
        destroySession();
        removeItem(KEYS.AUTH_TOKEN);
        removeItem(KEYS.IMPERSONATE_TOKEN);
        removeItem(KEYS.KEEP_LOGGED_IN);
        removeItem(KEYS.REFRESH_TOKEN);
        window.location.replace(routes.auth.signIn);
      }
    }
    return Promise.reject(error.response.data);
  },
);

export { API, APIRoutes, setAuthenticationToken, destroySession };
